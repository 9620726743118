






























































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FeedbackReplyDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
@Component({
  name: "FeedbackReplyList",
  components: {
    PagedTableView,
  },
})
export default class FeedbackReplyList extends Vue {
  queryForm = {};
  replyContent = "";
  isShow = false;
  feedbackId?: number = 0;
  btnDisabled = false;

  created() {
    if (this.$route.params.feedbackId) {
      this.feedbackId = Number(this.$route.params.feedbackId);
    }
  }

  // 获取表数据
  fetchData(params: any) {
    params.feedbackId = this.feedbackId ?? 0;
    return api.feedbackReply.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "feedbackReplyCreate",
    });
  }

  // 删除
  handleDelete(row: FeedbackReplyDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.feedbackReply.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 回复
  saveReply() {
    this.btnDisabled = true;
    if (!this.replyContent) {
      this.$message({
        type: "error",
        message: "请填写回复内容!",
      });
      this.btnDisabled = false;
      return;
    }
    api.feedbackReply
      .create({
        body: {
          feedbackId: this.feedbackId,
          content: this.replyContent,
        },
      })
      .then(() => {
        this.btnDisabled = false;
        this.isShow = false;
        this.$message.success("操作成功");
      });
  }

  cancel() {
    this.$router.back();
  }
}
